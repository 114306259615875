@import "colors";
@import "misc";

.footer {
  width: calc(100% - 60px);
  padding: 0 30px;
  font-size: 13px;

  color: white;
  background-color: $secondaryColor;

  .wrap {
    padding: 0 20%;

    @media (max-width: $tablet) {
      padding: 0;
      text-align: center;
    }

    .row-info {
      display: flex;
      flex-direction: row;
      padding: 0;
      @media (max-width: $mobile) {
        flex-direction: column;
        justify-content: center;
      }
    }

    .sitemap {
      display: flex;
      border-bottom: 1px solid #fff2;

      ul {
        list-style-type: none;
        flex-basis: 50%;
        padding: 0;
        
        li {
          margin: 1rem 0;
        }

        a {
          color: white;

          text-transform: uppercase;
        }
      }
      @media (max-width: $mobile) {
        flex-direction: column;
        justify-content: center;
      }
    }

    .info {
      height: 100px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      
      a {
        color: white;
        margin: 0 .7rem;
      }

      .copyright {
        @media (max-width: $mobile) {
          margin-bottom: 3rem;
        }
      }

      @media (max-width: $tablet) {
        flex-direction: column;
        justify-content: center;
        height: 220px;
        
        div {
          height: 30px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}